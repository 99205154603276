<template>
  <div>
    <!-- <v-row no-gutters>
      <v-col cols="12">
        <h1 class="fs-large">Imports</h1>
      </v-col>
    </v-row> -->
    <v-row no-gutters class="mt-5 align-items-center">
      <v-col cols="4">
        <h2>Import reviews</h2>
      </v-col>
      <v-col cols="8">
        <v-card elevation="2" class="mx-auto pa-2">
          <!-- <v-row>
            <v-col cols="12" class="d-inline-flex p-2 align-center">
              <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                  <path
                    d="M29.333 29.404H2.667A2.667 2.667 0 010 26.737V2.667A2.667 2.667 0 012.667 0h26.666A2.667 2.667 0 0132 2.667v24.07a2.667 2.667 0 01-2.667 2.667z"
                    fill="#FF8F00"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M29.333 32H2.667A2.667 2.667 0 010 29.333V7.111a2.667 2.667 0 012.667-2.667h26.666A2.667 2.667 0 0132 7.111v22.222A2.667 2.667 0 0129.333 32z"
                    fill="#DD2C00"
                    fill-rule="nonzero"
                  ></path>
                  <circle fill="#B71C1C" fill-rule="nonzero" cx="8" cy="9.778" r="1.778"></circle>
                  <circle fill="#B71C1C" fill-rule="nonzero" cx="24" cy="9.778" r="1.778"></circle>
                  <path
                    d="M24 9.778a8 8 0 01-8 8 8 8 0 01-8-8"
                    stroke="#FFF"
                    stroke-width="2"
                    stroke-linecap="round"
                  ></path>
                </g>
              </svg>
              <div class="ml-2">
                <h2>AliExpress</h2>
                Import reviews from AliExpress
              </div>
              <v-spacer></v-spacer>
              <v-btn class="ma-2" outlined>
                Import reviews
              </v-btn>
            </v-col>
          </v-row>
          <v-divider></v-divider> -->
          <v-row>
            <v-col cols="12" class="d-inline-flex p-2 align-center">
              <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <g fill="none">
                  <path d="M3 29v2c0 .6.4 1 1 1h24c.6 0 1-.4 1-1v-2H3zM22 1.6V7h5.4z" fill="#288200"></path>
                  <path
                    d="M31 13h-2V9h-8c-.6 0-1-.4-1-1V0H4c-.6 0-1 .4-1 1v12H1c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h30c.6 0 1-.4 1-1V14c0-.6-.4-1-1-1zm-19.4 8.8c.3 0 .5 0 .7-.1.2-.1.5-.2.7-.3v1.3c-.5.2-1 .3-1.6.3-.9 0-1.5-.2-2-.8-.5-.6-.7-1.2-.7-2.2 0-.6.1-1.1.3-1.6.2-.5.5-.8 1-1 .5-.2.9-.4 1.5-.4s1.2.1 1.8.4l-.5 1.2c-.2-.1-.4-.2-.6-.2-.2 0-.4-.1-.7-.1-.4 0-.7.2-.9.5-.2.3-.3.7-.3 1.2 0 1.3.4 1.8 1.3 1.8zm4-2.7c.1.1.4.2.9.5.5.2.8.5 1 .7.2.2.3.6.3 1s-.1.7-.3.9c-.2.2-.4.5-.8.6-.4.1-.7.2-1.2.2-.4 0-.7 0-1-.1-.3-.1-.5-.1-.8-.3v-1.4c.3.2.6.3.9.4.3.1.6.1.9.1.2 0 .4 0 .5-.1.1-.1.2-.2.2-.3 0-.1 0-.1-.1-.2L16 21c-.1-.1-.3-.2-.8-.4-.4-.2-.7-.3-.8-.5-.1-.2-.3-.3-.4-.5-.1-.2-.1-.4-.1-.7 0-.5.2-.9.6-1.2.4-.3.9-.4 1.6-.4.6 0 1.2.1 1.8.4l-.5 1.2c-.5-.2-1-.4-1.4-.4-.2 0-.3 0-.4.1-.1.1-.1.2-.1.3-.1 0 0 .1.1.2zm6.2 3.9H20l-1.9-5.7h1.7l.8 2.9c.2.6.2 1.1.3 1.3 0-.2.1-.4.1-.7 0-.3.1-.5.1-.6l.8-2.9h1.7L21.8 23z"
                    fill="#3FBC1A"
                  ></path>
                </g>
              </svg>
              <div class="ml-2">
                <h2>CSV file</h2>
                Import reviews from a spreadsheet <br />
                View the Reviews Import documentation.
              </div>
              <v-spacer></v-spacer>
              <v-btn class="ma-2" outlined @click="modalImportExcel = true">
                Import reviews
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <modal
      width="600px"
      model="modalImportExcel"
      title="Import reviews from a spreadsheet"
      @close="modalImportExcel = false"
      :callback="doImportCsv"
    >
      <template slot="content">
        <div v-if="progress">
          <page-loader />
        </div>
        <div v-else>
          <div class="d-flex">
            <span
              >Download the
              <a href="https://minio.dcomcy.com/dcomcy/review_templates/template.csv" target="_blank"
                >Dcomcy Import File Template.</a
              ></span
            >
          </div>
          <span
            >Fill in the reviews data you’d like to import and verify that your file meets all the requirements stated
            in the template. </span
          ><br />
          <span>Reviews that do not comply with the instructions will not be imported.</span>
          <v-file-input ref="fileUploader" @change="onChangeImage" accept=".csv" type="file" class="btn btn-default" />
          <!-- <v-checkbox
          v-model="overwrite"
          :label="`Overwrite any existing reviews that have the same reviewer's email.`"
        ></v-checkbox> -->
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      modalImportExcel: false,
      overwrite: false,
      formData: null,
      progress: false,
    };
  },
  components: {
    // RightSideBar,
  },
  methods: {
    toggleSideBar() {
      this.drawer = !this.drawer;
    },
    async onChangeImage(e) {
      this.formData = null;
      this.fileName = 'No file chosen';
      let file = e;
      this.formData = new FormData();
      this.formData.append('file', file);
      this.fileName = e.name;
    },
    resetImageUploader() {
      if (!this.formData.file) {
        this.$store.commit('setMessages', {
          type: 'error',
          messages: 'Csv file is invalid',
        });
        return;
      }
      this.$refs.fileUploader.value = '';
    },
    async doImportCsv() {
      this.progress = true;
      await this.$store.dispatch('importCsvReview', this.formData);
      this.progress = false;
      this.$router.push({
        name: 'website.apps.product-review.list',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-normal-custom--tab {
  text-transform: none;
  color: #000000;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}
.v-text-field {
  margin-top: 0px !important;
}
</style>
